import { Component } from '@angular/core';
import { body, save } from 'ionicons/icons';

export const fr = {
    VIN: 'NIV',
    Make: 'Marque',
    Model: 'Modèle',
    Year: 'Année',
    Trim: 'Version',
    Transmission_type: 'Type de transmission',
    Engine: 'Moteur',
    DriveTrain: 'Transmission',
    Fuel_type: 'Type de carburant',
    Odometer: 'Odomètre',
    XX_Number: 'XX (Chiffre)',
    Km_Miles: 'Km / Miles',
    Exterior_color: 'Couleur extérieure',
    City: 'Ville',
    Date: 'Date',
    Structure: 'Structure',
    Frame: 'Châssis',
    Subframe: 'Sous-châssis',
    Undercarriage_rust: 'Rouille du plancher',
    Body: 'Carrosserie',
    Body_condition: 'État de la carrosserie',
    Body_alignment: 'Alignement des panneaux de carrosserie',
    Body_seals: 'Joints',
    Doors: 'Portes',
    Trunk: 'Coffre',
    Roof: 'Toit',
    Convertible_top: 'Toit ouvrant',
    Paint_condition: 'État de la peinture',
    Fuel_tank_cap: 'Bouchon de réservoir de carburant',
    Glasses: 'Vitres',
    Front_windshield: 'Pare-brise avant',
    Rear_windshield: 'Pare-brise arrière',
    Windows: 'Fenêtres',
    Mirrors: 'Rétroviseurs',
    Wipers: 'Essuie-glaces',
    Charger_plugs: 'Prises de charge',
    Position_lights: 'Feux de position',
    Low_beam_lights: 'Feux de croisement',
    High_beam_lights: 'Feux de route',
    Turn_signals: 'Clignotants',
    Reverse_lights: 'Feux de recul',
    Brake_lights: 'Feux de freinage',
    Tires_information: 'Informations sur les pneus',
    Tires_tread_depth: 'Profondeur de la bande de roulement des pneus',
    Wheels_condition: 'État des roues',
    Spare_tire_condition: 'État des pneus de secours',
    Tire_condition: 'État des pneus',
    Tread_depth: 'Profondeur de la bande de roulement',
    Left_front: 'Avant gauche',
    Right_front: 'Avant droit',
    Right_rear: 'Arrière droit',
    Left_rear: 'Arrière gauche',
    Make_model: 'Marque et modèle',
    Dimension: 'Dimensions',
    Season: 'Saison',
    Wheels: 'Roues',
    Spare: 'Roue de secours',
    Computer_diagnosis: 'Diagnostic électronique',
    Engine_oil_level: "Niveau d'huile moteur",
    Engine_oil_leaks: "Fuites d'huile moteur",
    Transmission_leaks: 'Fuites de transmission',
    Brakes: 'Freins',
    Front_brake_maintenance: 'Entretien des freins avant',
    Front_brake_replacement: 'Remplacement des freins avant',
    Rear_brake_maintenance: 'Entretien des freins arrière',
    Rear_brake_replacement: 'Remplacement des freins arrière',
    Brake_fluid_condition: 'État du liquide de frein',
    Brake_fluid_leaks: 'Fuites de liquide de frein',
    Transfer_case_leaks: 'Fuites de la boîte de transfert',
    Differential_leaks: 'Fuites de différentiel',
    Coolant: 'Refroidissement',
    Coolant_condition: 'État du liquide de refroidissement',
    Coolant_fluid_leaks: 'Fuites de liquide de refroidissement',
    Air_filter_condition: 'État du filtre à air',
    Radiator: 'Radiateur',
    Radiator_condition: 'État du radiateur',
    Radiator_cap_sealing: 'Étanchéité du bouchon du radiateur',
    Battery: 'Batterie',
    Voltage: 'Tension',
    Terminal: 'Bornes',
    Cables: 'Câbles',
    Mounting: 'Fixation',
    Alternator: 'Alternateur',
    Cooling_fan_condition: 'État du ventilateur de refroidissement',
    Power_steering: 'Direction assistée',
    Belts: 'Courroies',
    V_belts: 'Courroies en V',
    Accessory_belt: "Courroie d'accessoire",
    Hoses_and_pipes: 'Durites et tuyaux',
    Suspension: 'Suspension',
    Shocks_and_bushings: 'Amortisseurs et silentblocs',
    Ball_joints_and_tie_rods: 'Rotules et biellettes de direction',
    Hand_brake: 'Frein à main',
    CV_joints_and_axles: 'Joint homocinétique et essieux',
    Exhaust_system: "Système d'échappement",
    Mufflers: 'Silencieux',
    Catalytic_converter: 'Catalyseur',
    Pipes: 'Tuyaux',
    Air_conditioning: 'Climatisation',
    A_C: 'A/C',
    Blower_motor: 'Moteur de soufflerie',
    Condenser: 'Condenseur',
    Dash: 'Tableau de bord',
    Lights: 'Lumières',
    Gauges: 'Jauges',
    Unusual_odors: 'Odeurs inhabituelles',
    No_cigarette_smell: 'Aucune odeur de cigarette',
    No_animal_smell: 'Aucune odeur animale',
    Cruise_control: 'Régulateur de vitesse',
    Radio_Navigation: 'Radio/Navigation',
    Speaker_sound: 'Son des haut-parleurs',
    Bluetooth: 'Bluetooth',
    Steering_wheel: 'Volant',
    Steering_wheel_condition: 'Condition du volant',
    Steering_wheel_heated: 'Volant chauffé',
    Power_door_locks: 'Verrouillage centralisé des portes',
    Power_windows: 'Vitres électriques',
    Power_mirrors: 'Rétroviseurs électriques',
    Sunroof: 'Toit ouvrant',
    Seats: 'Sièges',
    Seat_condition: 'État des sièges',
    Seat_belts: 'Ceintures de sécurité',
    Heated_seats: 'Sièges chauffants',
    Ventilated_seats: 'Sièges ventilés',
    Power_seats: 'Sièges électriques',
    Reversing_camera: 'Caméra de recul',
    Reverse_parking_sensors: 'Capteurs de stationnement arrière',
    Blind_spot_monitors: "Moniteurs d'angle mort",
    Condition_and_cleaning: 'État et nettoyage',
    Dashboard: 'Tableau de bord',
    Inner_door_panels: 'Panneaux intérieurs de porte',
    Summer_carpets: 'Tapis de sol été',
    Winter_floor_mats: 'Tapis de sol hiver',
    Sun_visors: 'Pare-soleil',
    Horn: 'Klaxon',
    Normal_engine_operating: 'Fonctionnement normal du moteur',
    Normal_transmission_operating: 'Fonctionnement normal de la transmission',
    Normal_gearbox_operating: 'Fonctionnement normal de la boîte de vitesses',
    Normal_differential_operating: 'Fonctionnement normal du différentiel',
    Normal_transfer_case_operating: 'Fonctionnement normal de la boîte de transfert',
    Normal_odometer_operating: 'Fonctionnement normal du compteur kilométrique',
    Normal_brake_system_operating: 'Fonctionnement normal du système de freinage',
    Normal_steering_operating: 'Fonctionnement normal de la direction',
    Normal_suspension_operating: 'Fonctionnement normal de la suspension',
    Normal_wheel_bearing_operating: 'Fonctionnement normal du roulement de roue',
    Normal_clutch_operating: "Fonctionnement normal de l'embrayage",
    Governmental_sticker: 'Vignette gouvernementale',
    Keys_included: 'Clés incluses',
    Car_maker_manual: 'Manuel du constructeur',
    Maintenance_invoices: "Facture d'entretien",
    Additional_comments: 'Commentaires supplémentaires',
    Vehicle_grade: 'Note du vehicule',
    General_photos: 'Photos generales',
    Esthetic_defects: 'Défauts esthétiques',
    Car_video: 'Vidéo du vehicule',
    Front: 'Avant',
    Driver_side: 'Côté conducteur',
    Passenger_side: 'Côté passager',
    Rear: 'Arrière',
    Undercarriage_front_side: 'Dessous avant',
    Undercarriage_driver_side: 'Dessous côté conducteur',
    Undercarriage_passenger_side: 'Dessous côté passager',
    Undercarriage_rear_side: 'Dessous arrière',
    Front_driver_wheel: 'Roue avant conducteur',
    Front_passenger_wheel: 'Roue avant passager',
    Rear_driver_wheel: 'Roue arrière conducteur',
    Rear_passenger_wheel: 'Roue arrière passager',
    Engine_bay: 'Compartiment moteur',
    Interior_of_the_trunk: 'Intérieur du coffre',
    ODBII_codes: 'Codes ODBII',
    Instrument_cluster_engine_turn_on: 'Tableau de bord moteur allumé',
    VIN_photo: 'Photo du VIN',
    Front_driver_compartment: 'Compartiment avant conducteur',
    Front_passenger_compartment: 'Compartiment avant passager',
    Back_passenger_compartment: 'Compartiment arrière passager',
    Back_driver_compartment: 'Compartiment arrière conducteur',
    Battery_test: 'Test de batterie',
    Alternator_test: 'Test d’alternateur',
    Front_driver_make_model: 'Marque et modèle',
    Front_passenger_make_model: 'Marque et modèle',
    Rear_driver_make_model: 'Marque et modèle',
    Rear_passenger_make_model: 'Marque et modèle',
    Front_driver_year: 'Année',
    Front_passenger_year: 'Année',
    Rear_driver_year: 'Année',
    Rear_passenger_year: 'Année',
    Front_driver_dimension: 'Dimensions',
    Front_passenger_dimension: 'Dimensions',
    Rear_driver_dimension: 'Dimensions',
    Rear_passenger_dimension: 'Dimensions',
    Front_driver_season: 'Saison',
    Front_passenger_season: 'Saison',
    Rear_driver_season: 'Saison',
    Rear_passenger_season: 'Saison',
    Front_driver_tread_depth: 'Profondeur de sculpture du conducteur du pneu avant',
    Front_passenger_tread_depth: 'Profondeur de sculpture du passager du pneu avant',
    Rear_driver_tread_depth: 'Profondeur de sculpture du conducteur du pneu arriere',
    Rear_passenger_tread_depth: 'Profondeur de sculpture du passager du pneu arriere',
    Computer_diagnosis_code: 'Système de diagnostic',
    Computer_diagnosis_photo: 'Photo du système de diagnostic',
    Coolant_photo: 'Photo du liquide de refroidissement',
    Floor_mats: 'Tapis de sol',
    Navigation_system: 'Système de navigation',
    Battery_photo: 'Photo de la batterie',
    Components_to_inspect: 'Composants à vérifier',
    Modified_vehicle: 'Vehicule modifié',
    Proof_of_commercial_use: 'Utilisé à des fins commerciales',
    Damaged_vehicle: 'Véhicule accidenté',
    Registration_card_available: "Carte d'enregistrement disponible",
    Photo_three_quarters_rear_driver_side: 'Trois-quart arrière côté conducteur du véhicule',
    Photo_three_quarters_front_driver_side: 'Trois-quart avant côté conducteur du véhicule',
    VIN_windshield: 'NIV sur le pare-brise',
    VIN_door: 'NIV sur la porte',
    Photo_three_quarters_rear_passenger_side: 'Trois-quart arrière côté passager du véhicule',
    Photo_three_quarters_front_passenger_side: 'Trois-quart avant côté passager du véhicule',
    License_plate: 'Plaque d’immatriculation',
    Final_comment: 'Commentaire final',
    Equipped_with_TPMS: 'Equipé avec TPMS',
    Engine_Operation_Normal: 'Opération du moteur normal',
    Transmission_Operation_Normal: 'Opération de la transmission normal',
    Cold_start_and_walkaround_video: 'Vidéo de démarrage à froid et tour du véhicule',
    Component_to_inspect_video: 'Vidéo de la vérification des composants',
    Component_to_inspect_photos: 'Photos de la vérification des composants',
    showroom: 'Condition showroom, Véhicule en excellent état ne nécessitant aucune réparation majeure. ',
    'drive-worthy': 'Condition sécuritaire, Véhicule en bon etat qui requiert des réparations mineures/majeures. ',
    unsafe: 'Condition dangereuse, Véhicule en mauvais etat qui requiert des réparations majeures. ',
    section: {
        Vehicle_information: 'Informations sur le vehicule',
        Structural: 'Structure',
        Lights: 'Lumières',
        Tires: 'Pneus',
        Mechanic: 'Mecanique',
        Features: 'Fonctionnalités',
        Road_test: 'Test routier',
        Administratives: 'Administratifs',
        Summary: 'Conclusion',
        Media: 'Média',
        Vehicle_status: 'État du vehicule',
        Vehicle_Components: 'Composants du véhicule'
    },
    tire: {
        front_driver_information: 'Informations sur la roue avant conducteur',
        front_passenger_information: 'Informations sur la roue avant passager',
        rear_driver_information: 'Informations sur la roue arriere conducteur',
        rear_passenger_information: 'Informations sur la roue arriere passager',
        apply: 'Appliquer la donnée uniformément sur toutes les roues'
    },
    general: {
        account: {
            greeting: 'Salut',
            name: 'Nom'
        },
        language: {
            en: 'Anglais',
            fr: 'Français'
        },
        cancel: 'Annuler',
        accept: 'Accepter',
        ok: "D'accord!",
        confirm: 'Confirmer',
        time: 'Heure',
        yes: 'Oui',
        edit: 'Modifier',
        no: 'Non',
        return: 'Retour',
        submit: 'Soumettre',
        next: 'Suivant',
        upload: 'Publier',
        modified: 'Modifiée',
        quit: 'Quitter',
        required: 'Requis',
        invalid: 'Invalide',
        previous: 'Précédent',
        welcome: 'Bienvenue! 🙏',
        refreshPrompt: "Aucune inspection trouvée\nBalayez l'écran vers le bas",
        back: 'Retour',
        continue: 'Continuer',
        display_name_message: "Comment t'appelles-tu ?",
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        here_you_go: 'Voilà!',
        close: 'Fermer',
        settings: 'Paramètres',
        update: 'Mise à jour',
        datesUnavailable: 'Dates indisponibles',
        selectStartDate: 'Date de début',
        selectEndDate: 'Date de fin',
        cancelChanges: 'Annuler les modifications',
        set: 'Confirmer',
        clear: 'Réinitialiser',
        summary: 'Sommaire',
        saveAndQuit: 'Soumettre',
        comment : "Commentaire pour",
        fillForm:
            'Veuillez remplir tout le formulaire. Toutes les sections à guauche doivent être en rouge, orange ou vert',
        acceptInspections: "Accepter les inspections",
        options: {
            good: 'Bon',
            average: 'Moyen',
            bad: 'Mauvais',
            na: 'N/A',
            name: 'Options',
            comment: 'Commentaire',
            comment_mandatory: 'Commentaire obligatoire',
            photo_optional: 'Photo optionnelle'
        }
    },
    purchase: 'Achat',
    errors: {
        disconnect: 'Vous avez été déconnecté par mesure de sécurité',
        forbidden: "Vous n'avez pas accès à cette ressource.",
        'missing-infos': 'Veuillez remplir toutes les informations demandées.',
        'wrong-password': 'Votre mot de passe est invalide.',
        'page-not-found': {
            title: 'Page introuvable',
            body: 'On ne semble pas trouver la page que vous voulez accéder. Vous pouvez tout de même utiliser nos outils!'
        },
        'not-found': "La ressource n'a pas été trouvée",
        'invalid-email': 'Le courriel est invalide',
        'weak-password': 'Votre mot de passe doit contenir 6 caractères',
        error: 'Une erreur est survenue',
        form: {
            empty_multiple_choice: 'Veuillez sélectionner une option',
            empty_text: 'Veuillez décrire la raison',
            invalid: 'Le formulaire est invalide'
        },
        invalidLogin: 'Identifiant ou mot de passe incorrect',
        invalidUser: "Identifiant d'inspecteur invalide : ",
        invalidZipCode: 'Code postal non valide',
        invalidPhone: 'Pas un numéro de téléphone valide',
        invalidStartDate: 'Date de début invalide',
        invalidEndDate: 'Date de fin invalide',
        invalidDates: 'Sélectionnez une date de début et de fin valide'
    },
    authentication: {
        email_already_in_use: 'Cette adresse courriel est déjà utilisée',
        connect: 'Se connecter',
        connect_helpText: {
            email: 'Entrer votre adresse courriel',
            password: 'Entrer votre mot de passe'
        },
        email: 'Courriel',
        loader: 'Authentification en cours...',
        login: 'Connexion',
        logout: 'Déconnexion',
        password: 'Mot de passe',
        connect_long: 'Déjà inscrit?',
        signup: "S'inscrire",
        signup_long: 'Pas encore inscrit? ',
        password_confirmation: 'Confirmation',
        password_reset: 'Réinitialiser le mot de passe',
        password_incorrect: 'Mot de passe non identique',
        password_confirmation_helpText: 'Confirmer votre mot de passe',
        confirmation: 'Merci de confirmer ton adresse courriel',
        'confirmation-page': {
            title: 'Merci de confirmer ton adresse courriel',
            'resend-code': 'Envoyer le lien à nouveau',
            message: "Un lien t'a été envoyé à ton adresse courriel"
        },
        forgot_password: "J'ai oublié mon mot de passe",
        password_modal: {
            title: 'Réinitialiser mon mot de passe',
            confirm: 'Réinitialiser',
            email: 'Courriel',
            message: "Un lien t'a été envoyé à ton adresse courriel"
        },
        password_reset_successful: 'Ton mot de passe a été modifié avec succès!',
        new_code_sent: 'Un nouveau code a été envoyé'
    },
    inspection: {
        start: "Commencer l'inspection",
        details: {
            title: "Demande d'inspection",
            seller: {
                type: {
                    title: 'Type de vendeur',
                    owner: 'Propriétaire',
                    dealership: 'Concessionnaire',
                    dealer: 'Concessionnaire',
                    private: 'Privé',
                    business: 'Entreprise'
                },
                title: 'Vendeur',
                name: 'Nom du vendeur',
                dealership: 'Nom du concessionnaire'
            },
            client: {
                title: 'Client',
                name: 'Nom du client',
                extraDemande: 'Demande supplémentaire',
                callRequest: "Demande d'appel",
                lastName: 'Nom de famille',
                lastNamePrompt: 'Entrez le nom de famille du client',
                firstName: 'Prénom',
                firstNamePrompt: 'Entrez le prénom du client',
                prefLang: 'Langue préférée',
                prefLangPrompt: 'Entrez la langue préférée du client (fr/en)',
                telNumber: 'Numéro de téléphone',
                telNumberPrompt: 'Entrez le numéro de téléphone du client',
                emailPrompt: "Entrez l'adresse courriel du client"
            },
            vehicle: {
                title: 'Véhicule',
                make: 'Marque',
                makePrompt: 'Entrez la marque du véhicule',
                model: 'Modèle',
                modelPrompt: 'Entrez le modèle du véhicule',
                year: 'Année',
                yearPrompt: "Entrez l'année du modèle du véhicule",
                price: 'Prix',
                pricePrompt: 'Entrez le prix du véhicule',
                mileage: 'Kilométrage',
                mileagePrompt: 'Entrez le kilométrage du véhicule',
                carFax: 'URL CarFax',
                km: '{{ km }} km',
                amount: '{{ amount }} $',
                components: 'Composants à inspecter',
                componentSubcomponent : 'Composant - Sous-composant:',
                descriptionIssue : 'Description du problème:',	
            },
            language: 'Langue préférée',
            phone: 'Numéro de téléphone',
            address: 'Adresse',
            street_number: 'Numéro civique',
            street: 'Rue',
            city: 'Ville',
            province: 'Province',
            zipcode: 'Code postal',
            warning: {
                clientIsSeller: 'Le client est le vendeur.',
                requireCall: {
                    yes: "Appel&nbsp;<b>requis</b>&nbsp;après l'inspection.",
                    no: "Appel&nbsp;<b>non requis</b>&nbsp;après l'inspection."
                }
            },
            error: {
                no_inspection: "Aucune inspection trouvée. Veuillez retourner à la page d'accueil."
            }
        },
        tabs: {
            all_inspections: 'Inspections Disponibles',
            my_inspections: 'Mes inspections',
            inspection_history: 'Historique'
        },
        adhoc_inspection: {
            title: "Problème technique? Commencez une nouvelle inspection Ad hoc à partir d'ici!",
            label: "Formulaire d'inspection"
        },
        card: {
            details_button: 'Voir la demande',
            errors: {
                no_inspection: 'Aucune inspection trouvée'
            },
            expiration: {
                hour: 'Créée depuis {{ hours }} h',
                min: 'Créée depuis {{ mins }} min',
                expired: 'URGENT - Créée depuis {{ hours }} h'
            }
        },
        mandate_page: {
            accept: 'Accepter',
            confirm: 'Confirmer',
            debut: "Débuter l'inspection",
            resume: "Continuer l'inspection",
            removeAcceptedInspection: 'Retirer de mes inspections',
            enter_date: "Entrer la date et l'heure de l'inspection",
            enter_time: "Entrer l'heure de l'inspection",
            confirm_date: "Confirmer la date de l'inspection?",
            badDateModal: {
                title: 'Date invalide',
                message: 'Vous devez indiquer une date postérieure à la date et le temps courant.'
            },
            modifiedDateReason: {
                title: 'Raison du report',
                prompt: 'Choisir une raison',
                meteo: 'Condition météorologique',
                buyer: "Demande de l'acheteur",
                seller: 'Demande du vendeur',
                inspector: "Demande de l'inspecteur",
                other: 'Autre',
                otherInput: 'Précisez la raison du report svp'
            },
            reportModal: {
                title: 'Signaler un problème',
                selectResponse: 'Séléctionner une raison dans la liste ci-dessous',
                chooseResponse: 'Choisir une réponse',
                noResponse: 'Vendeur ne répond pas',
                contactLater: 'Vendeur va me recontacter plus tard',
                rescheduleInspection: "Modifier la date d'inspection",
                other: 'Autre',
                alreadySold: 'Voiture déjà vendue',
                sellerRefusal: 'Refus du vendeur de faire inspecter',
                back: 'Retour',
                otherInput: 'Précisez le problème que vous rencontrez svp',
                otherResponse: 'Raison du problème',
                confirm: 'Confirmer',
                problemReported: 'Problème Signalé',
                reason: 'Raison',
                null: 'Aucune'
            }
        },
        addressModal: {
            prompt: 'Adresse à ajouter',
            title: "Modifier l'adresse",
            streetNumberPrompt: 'Entrez le numéro civique',
            streetNamePrompt: 'Entrez le nom de rue ',
            cityPrompt: 'Entrez le nom de la ville',
            provincePrompt: 'Sélectionnez la province',
            zipCodePrompt: 'Entrez le code postal'
        },

        clientModal: {
            title: 'Information du client'
        },
        vehicleModal: {
            title: 'Information du véhicule'
        },
        status: {
            undefined: 'Disponible',
            null: 'Disponible',
            waiting: 'En attente',
            accepted: 'Acceptée',
            confirmed: 'Cédulée',
            rescheduled: 'Reportée',
            started: 'Commencée',
            completed: 'Complétée',
            cancelled: 'Annulée',
            archived: 'Archivé'
        },
        type:{
            type: 'Type',
            psi: 'Psi',
            troubleshoot: 'Troubleshoot',
            complete: 'Complete',
            all: 'All'
          },
        remove_status: {
            title: "Retirer l'inspection",
            message: 'Êtes-vous sûr de vouloir supprimer cette inspection de Mes Inspections?',
            confirm: 'Confirmer',
            cancel: 'Annuler'
        },
        status_dates: {
            created: 'Créée',
            accepted: 'Acceptée',
            inspection: 'Inspection',
            pending: 'En attente',
            schedule: 'À céduler'
        },
        problemReported: 'Problème signalé',
        form: {
            error_message: 'Remplir le formulaire',
            next: 'Suivant',
            selectOption: "Sélectionnez toutes les options qui s'appliquent",
            type_prompt: "Quel type d'inspection voulez-vous créer?",
            type_iA: 'Assurance',
            type_PSI: 'PSI',
            type_reg: 'Regulière',
            placeholder: 'Entrez la valeur',
            adHocPrompt: 'Ceci est une inspection Ad hoc. Veuillez informer Kiwiz en appelant au',
            adHocTitle: 'Inspection Ad hoc',
            image_reminder: 'La photo doit être en mode paysage'
        },
        exit_form: {
            title: 'Quitter?',
            message: 'Êtes-vous sûr de vouloir quitter?\n' + 'Votre progrès sera enregistré.'
        },
        submit_form: {
            title: 'Formulaire complété!',
            message: "Êtes-vous sûr(e) d'avoir rempli correctement toutes les informations?",
            clientCallPrompt: 'Veuillez appeler le client en appelant au:',
            kiwizCallPrompt: 'Veuillez informer Kiwiz en appelant au:',
            confirm: 'Envoyer le formulaire',
            troubleshootMessage:
                "Veuillez écrire 'en attente' dans le commentaire final et envoyer un email avec les 6 derniers chiffres du VIN comme sujet et le commentaire final comme contenu à cette adresse email operations@carinspect.com.",
            remove_status: {
                cancel: 'Annuler',
                confirm: 'Confirmer'
            }
        },
        resume_form: {
            title: 'Continuer la progression?',
            message: 'Souhaitez-vous naviguer à la question où vous vous êtes arrêté(e) la dernière fois?'
        },
        error_vin: 'Le NIV doit être exactement 17 caractères',
        help: {
            title: 'Instructions',
            message: 'Comment vérifier ce composant:',
            Frame: 'Vérification visuelle: utiliser une lampe et se baisser sous le véhicule de chaque coté. Attention au pickup: tester la rigidité de la benne',
            Subframe:
                'Vérification visuelle: utiliser une lampe et se baisser sous le véhicule de chaque coté. Attention au pickup: tester la rigidité de la benne.',
            Undercarriage_rust:
                'Vérification visuelle: utiliser une lampe et se baisser sous le véhicule de chaque coté. Attention au pickup: tester la rigidité de la benne',
            Body_condition: 'Vérification visuelle: recherche de défauts, rouille, stone chip, etc. ',
            Body_alignment:
                'Vérification visuelle: comparer les espaces entre chaque panneau de carrosserie. Attention aux parechocs AV et AR: vérification très rigoureuse',
            Body_seals: 'Vérification visuelle: recherche de joints collés, séchés ou déchirés',
            Doors: "Tester l'ouverture et la fermeture, des charnières et mécanisme ",
            Trunk: "Tester l'ouverture et la fermeture, des charnières et mécanisme ",
            Roof: 'Vérification visuelle: recherche de défauts, rouille, stone chip, etc. .',
            Convertible_top:
                "Tester l'ouverture et la fermeture, le mécanisme et valider qu'il ne manque pas de graisse. Vérification visuelle: recherche de déchirures ou défauts de la toile",
            Paint_condition:
                'Vérification visuelle: état général de la peinture et recherche de différence de couleur ou traces de nouvelle peinture; Passer votre doigt sur la bordure de capot et coffre pour vérifier si gouttes de peinture présente)  ',
            Fuel_tank_cap: "S'assurer de l'ouverture et la fermeture facile et de l'étanchéité du bouchon ",
            Front_windshield: 'Vérification visuelle: fissures, éclats, opacité',
            Rear_windshield: 'Vérification visuelle: fissures, éclats, opacité',
            Windows:
                "Tester l'ouverture et la fermeture de tous les boutons des fenêtre. Vérification visuelle: fissures, éclats, opacité",

            Mirrors:
                'Tester le fonctionnement du miroir électrique des deux côtés. Vérification visuelle: fissures, éclats, opacité',

            Wipers: 'Tester les différentes vitesses des essuies glace et le lave glace. Vérification visuelle: déchirures, rouilles, etc.',
            Position_lights: 'Vérifications visuelles: filmer avec le téléphone ou utiliser un miroir',
            Low_beam_lights: 'Vérifications visuelles: filmer avec le téléphone ou utiliser un miroir',
            High_beam_lights: 'Vérifications visuelles: filmer avec le téléphone ou utiliser un miroir',
            Turn_signals: 'Vérifications visuelles: filmer avec le téléphone ou utiliser un miroir',
            Reverse_lights: 'Vérifications visuelles: filmer avec le téléphone ou utiliser un miroir',
            Brake_lights: 'Vérifications visuelles: filmer avec le téléphone ou utiliser un miroir',

            Tire_condition: 'Vérification visuelle: fissuré, craquelé, bosselé, ect. ',

            Computer_diagnosis:
                "Utiliser scanner OBDII et prendre photo ou capture d'écran. Attention: mentionner TOUS les codes erreurs confirmés",
            Engine_oil_level:
                "Tester avec voiture sur une surface plate, retirer la jauge d'huile et l'essuyiez avant de la replonger dans le réservoir d'huile et de la retirer une nouvelle fois pour voir le niveau précisément ",
            Engine_oil_leaks:
                'Vérification visuelle: recherche de fuites. Attention: si présence de fuite, spécifier le niveau 1, 2 ou 3 et mentionner la provenance',
            Transmission_leaks:
                "Vérification visuelle: utiliser une lampe et vérifier l'état des disques et plaquettes. Tester lors de l'essai routier. Attention: Soyez TRÈS conservateurs dans l'évaluation",

            Need_brake_maintenance:
                "Vérification visuelle: utiliser une lampe et vérifier l'état des disques et plaquettes. Tester lors de l'essai routier. Attention: Soyez TRÈS conservateurs dans l'évaluation",
            Need_brake_replacement:
                "Vérification visuelle: utiliser une lampe et vérifier l'état des disques et plaquettes. Tester lors de l'essai routier. Attention: Soyez TRÈS conservateurs dans l'évaluation",
            Brake_fluid_condition:
                "Vérification visuelle: niveau du liquide de frein. Tester qualité du liquide avec l'outil approprié. Attention: si présence de fuite, spécifier le niveau 1, 2 ou 3 ",
            Brake_fluid_leaks:
                'Vérification visuelle: flexibles, vis de purge et ensemble du système de freinage. Attention : les fuites de liquide de frein peuvent être très dangereuses. ',
            Transfer_case_leaks:
                'Vérification visuelle: utiliser lampe. Attention: si présence de fuite, spécifier le niveau 1, 2 ou 3 ',
            Differential_leaks:
                'Vérification visuelle: utiliser lampe. Attention: si présence de fuite, spécifier le niveau 1, 2 ou 3 ',

            Coolant_condition:
                "Vérification visuelle: niveau et qualité du liquide à l'aide d'un testeur de liquide de refroidissement",
            Coolant_fluid_leaks:
                "Vérification visuelle: durites, passage du liquide, les joints et l'état du radiateur. Attention: si présence de fuite, spécifier le niveau 1, 2 ou 3 ",
            Air_filter_condition: 'Vérification visuelle: ouvrir la boite et vérifier le filtre à air',

            Radiator_condition: 'Vérification visuelle: recherche de débris, rouille. Spécifiez si entretien requis',
            Radiator_cap_sealing: 'Vérification visuelle: étanchéité et condition du joint ',
            Voltage:
                'Attention : Le moteur doit être arrêté. Brancher le voltmètre. Tension entre 12,4 et 13 : vert. Tension entre 11,7 et 12,4 : orange. Tension inférieure à 11,7 : rouge.',
            Terminal: 'Vérification visuelle: corrosion, oxydation des bornes, etc. ',
            Cables: 'Vérification visuelle: déchirure ou autre',
            Mounting: 'Vérification visuelle: corrosion. Tester si batterie correctement fixée',
            Alternator:
                'Attention: le moteur doit être allumé. Branchez le voltmetre lors. Voltage entre 13V et 15V: vert. Voltage inférieur à 13V: orange ou rouge. ',
            Cooling_fan_condition: 'Vérification visuelle : recherche de fuites ou de dommages.',
            Power_steering:
                "Vérification visuelle du niveau de liquide de servo-direction et détecter lors de l'essaie routier tout bruit ou resistance dans la direction",

            V_belts:
                'Vérification visuelle des durites et tout les passages de liquides si trop sec, fissurés, fuites ou trop tendue. Mentionner si présence de factures justifiant le remplacement de la timing belt',
            Hoses_and_pipes:
                "Vérification visuelle: durites et tous les passages de liquide à la recherche d'un assèchement excessif, de fissures, de fuites ou d'une tension excessive",

            Shocks_and_bushings:
                "Vérification visuelle à l'arrêt: mettre son poids sur une suspension pour faire un test de rebond ET test lors de l'essai routier pour détecter les bruits de suspension",
            Ball_joints_and_tie_rods:
                "Vérification visuelle à l'arrêt: mettre son poids sur une suspension pour faire un test de rebond ET test lors de l'essai routier pour détecter les bruits de suspension",
            Hand_brake: 'Actionner le frein à main électrique ou manuelle pour le tester',
            CV_joints_and_axles: "Testez les joints homocinétiques (CV) et les essieux pendant l'essai routier ",

            Mufflers: 'Vérification visuelle: recherche de fuite lorsque le moteur est allumé ',
            Catalytic_converter: 'Vérification visuelle: recherche de fuite lorsque le moteur est allumé ',
            Pipes: 'Vérification visuelle: recherche de fuite lorsque le moteur est allumé',
            Sunroof:
                "Tester l'ouverture et la fermeture, le mécanisme, les différentes positions et valider qu'il ne manque pas de graisse. Vérification visuelle: état de la vitre",
            Dashboard:
                "Vérifier l'intérieur du véhicule (tableaux de bord , sièges , plafond), voir s'il y'a des taches graves ou déchirures) ",
            Sun_visors: "Voir si le pare-soleil s'ouvre correctement et voir si la lampe fonctionne.",
            Horn: 'Klaxonner',
            Normal_engine_operating:
                "Observer et écouter le moteur à froid et à chaud s'il y'a pas de claquements ou cliquetis",
            Normal_transmission_operating:
                "Lors de l'essai routier faire une pointe à au moins 80km pour voir le passage de rapports",
            Normal_gearbox_operating:
                "Voiture à l'arrêt passer P R N D lune après l'autre et voir si c'est facile à passer ou dur avec bruits )",
            Normal_differential_operating: "Écouter s'il y'a un bruit de différentiel lors de l'essai  routier ",
            Normal_transfer_case_operating: 'Essai routier',
            Normal_odometer_operating: "Kilométrage avant et après l'essai routier .",
            Normal_brake_system_perating:
                "Road test : freiner plusieurs fois à plusieurs degrés d'intensité pour voir si les freins répondent bien",
            Normal_steering_operating:
                'During the test drive, check the steering for responsiveness and ease of use. There should be no excessive play or vibration, and the vehicle should not pull to one side.',
            Normal_suspension_operating:
                'Observe the vehicle handling and comfort during the test drive over various surfaces to ensure the suspension provides appropriate damping and support.',
            Normal_wheel_bearing_operating:
                'Listen for any humming or grinding noises from the wheel bearings during the test drive, which might indicate wear or damage.',
            Normal_Clutch_operating:
                "Manuelle: tester le point de patinage. Automatique: Vérifier lors de l'essai routier si une ou plusieurs vitesses se désenclenchent ",
            Governemental_sticker: 'Province sticker (Service Ontario, SAAQ, CVSE, Alberta, etc.) '
        }
    }
};
